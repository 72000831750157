import React from "react";
import PropTypes from "prop-types";
import BlogArticleCardComponent from "./BlogArticleCardComponent";
import {observer} from "mobx-react";
import {observable} from "mobx";


import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

@observer
class BlogArticleCarouselComponent extends React.Component {

    @observable
    startIndex = 0;

    @observable
    maxIndex = 0;

    increaseStartIndex() {
        //check if enough articles available
        if (this.maxIndex < this.props.articles.length - 1) {
            this.startIndex += 1;
            this.maxIndex += 1;
        }
        this.setState({})
    }

    decreaseStartIndex() {
        if (this.startIndex !== 0) {
            this.startIndex -= 1;
            this.maxIndex -= 1;
        }
        this.setState({})
    }

    componentDidMount() {
        if (this.props.articles.length > 3) {
            this.maxIndex = 2;
        } else {
            this.maxIndex = this.props.articles.length - 1;
        }
        this.setState({})
    }

    render() {
        const {articles} = this.props;
        if (!articles && articles.length < 1) {
            console.log('Src is not acceptable');
            return null;
        }

        const blogArticles = [];

        for (let i = this.startIndex; i <= this.maxIndex; i++) {
            blogArticles.push(
                <Grid.Column width={4} key={i} stretched>
                    <BlogArticleCardComponent
                        titleText={articles[i].header}
                        teaserText={" "}
                        imageLink={articles[i].thumbnail}
                        buttonLink={articles[i].id}
                        dateText={articles[i].createdAt}
                        tags={articles[i].tags}
                    />
                </Grid.Column>
            )
        }

        return (
            <Grid.Row stretched>
                <Grid.Column width={2} verticalAlign={"middle"}>
                    {articles.length > 3 ?
                        <Button className={"left-carousel-button"}
                                onClick={() => this.decreaseStartIndex()}>
                            <Icon name={"angle left"} size={"huge"}/>
                        </Button>
                        :
                        null
                    }
                </Grid.Column>

                {blogArticles}
                <Grid.Column width={2} verticalAlign={"middle"} stretched>
                    {articles.length > 3 ?
                        <Button className={"right-carousel-button"}
                                onClick={() => this.increaseStartIndex()}>
                            <Icon name={"angle right"} size={"huge"}/>
                        </Button>
                        :
                        null
                    }
                </Grid.Column>
            </Grid.Row>
        )
    }
}

BlogArticleCarouselComponent.propTypes = {
    articles: PropTypes.array,
};

BlogArticleCarouselComponent.defaultProps = {
    articles: [],
};

export default BlogArticleCarouselComponent