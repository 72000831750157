import React from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
import browserHistory from "../../helper/browserHistory";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import config from "../../config/main.config";
import BlogArticleCardComponent from "../blog/BlogArticleCardComponent";
import DatePicker from "react-datepicker";
import moment from "moment";
import BlogArticleCarouselComponent from "../blog/BlogArticleCarouselComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import {getTimespanToShow} from "../../helper/util";

const PAGE_LINK = "Aktuelles";
const NETWORK_CATEGORY = "1b8868e9-2932-4618-a274-40b5bd323c64";
const ADDER = 3;
const BIG_ADDER = 3;


@inject("stores")
@observer
class CurrentPage extends React.Component {

    @observable
    allCategories = [];

    @observable
    dropDownOpen = false;

    @observable
    showAll = true;

    @observable
    timespan = {
        startDate: null,
        endDate: null
    };

    @observable
    networkProjectsAmount = ADDER;

    @observable
    projectsAmount = BIG_ADDER;

    @observable
    searchString = "";

    constructor(props) {
        super(props);

        if (props.staticContext && props.staticContext.length > 0) {
            let projects = props.staticContext;
            let networkProjects = [];
            let notNetworkProjects = [];
            for (let j = 0; j < projects.length; j++) {
                if (projects[j].categoryIds.includes(NETWORK_CATEGORY)) {
                    networkProjects.push(projects[j]);
                } else {
                    notNetworkProjects.push(projects[j]);
                }
            }

            this.state = {
                allProjects: networkProjects,
                filteredProjects: notNetworkProjects,
                networkProjects: [],
                textStore: props.stores.textStore.texts,
            }

        } else {
            this.state = {
                allProjects: [],
                filteredProjects: [],
                networkProjects: [],
                textStore: {},
            }
        }


    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.stores.trackingStore.addVisit(PAGE_LINK);
        this.showAll = false;

        //Get all Projects
        fetch(config.BASE_URL + 'blog/amount-articles/0', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                // console.log(data);
                /*Sort Articles
                articles.sort(function (a, b) {
                    a = new Date(a.createdAt);
                    b = new Date(b.createdAt);
                    return a > b ? -1 : a < b ? 1 : 0;
                });*/

                let projects = data.articles;
                let networkProjects = [];
                let notNetworkProjects = [];
                for (let j = 0; j < projects.length; j++) {
                    if (projects[j].categoryIds.includes(NETWORK_CATEGORY)) {
                        networkProjects.push(projects[j]);
                    } else {
                        notNetworkProjects.push(projects[j]);
                    }
                }

                this.setState({
                    ...this.state,
                    allProjects: notNetworkProjects,
                    filteredProjects: notNetworkProjects,
                    networkProjects: networkProjects
                })
                if(this.props.location.search.includes("?search=")){
                    const obj = {target: {value: this.props.location.search.replace("?search=","")}}
                    this.searchArticle(obj)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        //Get all Subcategories of project
        fetch(config.BASE_URL + "blog/sub-categories/264c1127-aa75-436e-ae98-c2a020dea05c", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.allCategories.push({
                        name: "Alle Themen",
                        active: true,
                        id: "0"
                    });
                    if (json.hasOwnProperty("sub_categories")) {
                        for (let i = 0; i < json.sub_categories.length; i++) {
                            if (json.sub_categories[i].name.toLowerCase() !== "presse" && json.sub_categories[i].name.toLowerCase() !== "unser netzwerk" && json.sub_categories[i].name.toLowerCase() !== "eigene projekte") {
                                this.allCategories.push({
                                    name: json.sub_categories[i].name,
                                    active: false,
                                    id: json.sub_categories[i].id
                                });
                            }
                        }
                    }
                });
            }
        }).catch(
            error => {
                throw error;
            }
        );
    }

    setCategoryFilter(index) {
        //Reset category filter
        if (index === 0 && !this.allCategories[index].active) {
            //reset all others
            this.allCategories[0].active = true;
            for (let i = 1; i < this.allCategories.length; i++) {
                this.allCategories[i].active = false;
            }
        } else {
            this.allCategories[index].active = !this.allCategories[index].active;
            let allFalse = true;
            for (let i = 1; i < this.allCategories.length; i++) {
                if (this.allCategories[i].active) {
                    allFalse = false;
                    break;
                }
            }

            this.allCategories[0].active = allFalse;
        }

        this.filterProjects();
    }

    filterProjects() {
        let filterIds = [];
        for (let i = 1; i < this.allCategories.length; i++) {
            if (this.allCategories[i].active) {
                filterIds.push(this.allCategories[i].id);
            }
        }
        let networkProjects = [];
        let notNetworkProjects = [];
        for (let j = 0; j < this.state.allProjects.length; j++) {
            //console.log(this.state.allProjects[j].categoryIds)
            if (this.state.allProjects[j].categoryIds.includes(NETWORK_CATEGORY)) {
                networkProjects.push(this.state.allProjects[j]);
            } else {
                notNetworkProjects.push(this.state.allProjects[j]);
            }
        }

        //filter projects category
        let categoryFilteredProjects = [];
        if (filterIds.length === 0) {
            categoryFilteredProjects = notNetworkProjects;
        } else {
            let filteredProjects = notNetworkProjects;
            for (let j = 0; j < filterIds.length; j++) {
                let idFilteredProjects = [];
                for (let i = 0; i < filteredProjects.length; i++) {
                    if (filteredProjects[i].categoryIds.includes((filterIds[j]))) {
                        idFilteredProjects.push(this.state.allProjects[i]);
                    }
                }
                filteredProjects = idFilteredProjects;
            }
            categoryFilteredProjects = filteredProjects;
        }

        //filter projects timespan
        if (this.timespan.startDate === null || this.timespan.endDate === null) {
            this.setState({
                ...this.state,
                filteredProjects: categoryFilteredProjects,
                networkProjects: networkProjects
            })
        } else {
            let startDate = moment(this.timespan.startDate);
            let endDate = moment(this.timespan.endDate);
            let timeFilteredProjects = [];
            for (let i = 0; i < categoryFilteredProjects.length; i++) {
                let projectDate = moment(categoryFilteredProjects[i].createdAt);
                if (projectDate.startOf('day').isAfter(startDate.startOf('day')) && projectDate.startOf('day').isBefore(endDate.startOf('day'))) {
                    timeFilteredProjects.push(categoryFilteredProjects[i])
                }
            }

            this.setState({
                ...this.state,
                filteredProjects: timeFilteredProjects,
                networkProjects: networkProjects
            })
        }
    }

    slugify(str) {
        return String(str)
            .normalize('NFKD') // split accented characters into their base characters and diacritical marks
            .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
            .trim() // trim leading or trailing whitespace
            .toLowerCase() // convert to lowercase
            .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
            .replace(/\s+/g, '-') // replace spaces with hyphens
            .replace(/-+/g, '-'); // remove consecutive hyphens
    }

    searchArticle(e) {
        let value = e.target.value;
        if (!!value) {

            browserHistory.push("/current?search="+ this.slugify(value))
            let filteredProjects = this.state.filteredProjects.filter(project => {
                return project.header.toLowerCase().includes(value.toLowerCase()) || this.slugify(project.tags).includes(this.slugify(value));
            })
            this.setState({filteredProjects: filteredProjects})
        } else {
            this.filterProjects();
        }
        this.searchString = value;
    }

    setStartDate(date) {
        if (date === null) {
            console.log("ERROR");
        } else {
            this.timespan.startDate = date;
            this.filterProjects();
        }
    }

    setEndDate(date) {
        if (date === null) {
            console.log("ERROR");
        } else {
            this.timespan.endDate = date;
            this.filterProjects();
        }
    }

    redoTimeSpan() {
        this.timespan.startDate = null;
        this.timespan.endDate = null;
        this.filterProjects();
    }

    render() {
        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        //this weird construct so on computer there are always two widths padding for the sides
        let showArticles = [];
        for (let i = 0; i < this.state.filteredProjects.length; i++) {
            if (i === this.projectsAmount && !this.showAll) {
                break;
            }
            if (i % 3 === 0 && !this.showAll) {
                showArticles.push(<Grid.Column width={2} only={"computer"} key={i + "x"}>
                </Grid.Column>);
                if (i !== 0) {
                    showArticles.push(<Grid.Column width={2} only={"computer"} key={i + "xy"}>
                    </Grid.Column>);
                }
            }
            let article = this.state.filteredProjects[i];
            showArticles.push(
                <Grid.Column computer={4} tablet={8} mobile={16} stretched key={i}>
                    <BlogArticleCardComponent
                        titleText={article.header}
                        teaserText={article.description}
                        imageLink={article.thumbnail}
                        buttonLink={article.id}
                        dateText={article.createdAt}
                        tags={article.tags}
                    />
                </Grid.Column>
            );
        }

        let showFilterButtons = this.allCategories.map((category, index) =>
            <Grid.Column computer={5} tablet={8} mobile={16} key={index + "Cat"}>
                <Button circular fluid
                        style={{"marginBottom": "20px"}}
                        className={category.active ? "call-to-action-button-blue" : "inactive-cat-button"}
                        onClick={() => this.setCategoryFilter(index)}>
                    {category.name.toUpperCase()}
                </Button>
            </Grid.Column>
        );

        let showNetworkProjects = [];
        for (let i = 0; i < this.state.networkProjects.length; i++) {
            if (i === this.networkProjectsAmount) {
                break;
            }
            showNetworkProjects.push(<Grid.Column computer={12} tablet={14} mobile={16}>
                <BlogArticleCardComponent
                    titleText={this.state.networkProjects[i].header}
                    teaserText={this.state.networkProjects[i].description}
                    imageLink={this.state.networkProjects[i].thumbnail}
                    buttonLink={this.state.networkProjects[i].id}
                    dateText={this.state.networkProjects[i].createdAt}
                    tags={this.state.networkProjects[i].tags}
                />
            </Grid.Column>);
        }

        return (
            <Grid>
                <TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'current'}
                    imageLinkId={"current-title-image-id"}
                    headerId={"current-title-header-id"}
                    subheaderId={"current-subtitle-header-id"}
                />
                <Grid.Row>
                    <Grid.Column width={2}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({...this.state});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section
                                className={"active-breadcrumb nav-item-green"}>AKTUELLES</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={14} tablet={14} mobile={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>
                            <EditSimpleValueComponent textStore={this.state.textStore} id={"current-sector-2-title-id"}
                                                      namespace={'current'}/></h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row-bottom"}>
                    <Grid.Column computer={12} tablet={14} mobile={16}>
                        <p className={"text-block"}>

                            <EditSimpleValueComponent textStore={this.state.textStore} id={"current-sector-1-text-id"}
                                                      namespace={'current'}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={12} tablet={16} mobile={16}>
                        <Grid>
                            <Grid.Row centered>
                                <Grid.Column computer={5} tablet={8} mobile={16}>
                                    <Input label={'Suchen'} iconPosition={'right'} icon={'search'}
                                           value={this.searchString} onChange={e => this.searchArticle(e)}/>
                                </Grid.Column>
                                <Grid.Column computer={5} tablet={8} mobile={16}>
                                    <Dropdown
                                        style={{marginBottom: "20px"}}
                                        text={this.timespan.startDate == null || this.timespan.endDate == null ? "ZEITRAUM" : getTimespanToShow(this.timespan)}
                                        className={this.timespan.startDate == null || this.timespan.endDate == null ?
                                            "inactive-cat-button timespan-dropdown"
                                            :
                                            "call-to-action-button-blue timespan-dropdown"}
                                        simple>
                                        <Dropdown.Menu>
                                            <Segment>
                                                <Form>
                                                    <Form.Group widths={"equal"}>
                                                        <Form.Field>
                                                            <label className={"text-block"}>START DATUM</label>
                                                            <DatePicker
                                                                selected={this.timespan.startDate}
                                                                onChange={date => this.setStartDate(date)}
                                                                selectsStart
                                                                startDate={this.timespan.startDate}
                                                                endDate={this.timespan.endDate}
                                                                dateFormat="dd.MM.yyy"/>
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <label className={"text-block"}>END DATUM</label>
                                                            <DatePicker
                                                                selected={this.timespan.endDate}
                                                                onChange={date => this.setEndDate(date)}
                                                                selectsEnd
                                                                endDate={this.timespan.endDate}
                                                                minDate={this.timespan.startDate}
                                                                dateFormat="dd.MM.yyy"/>
                                                        </Form.Field>
                                                    </Form.Group>
                                                    <Form.Field>
                                                        <Button icon labelPosition='right'
                                                                inverted color='green'
                                                                onClick={() => this.redoTimeSpan()}>
                                                            Zurücksetzen
                                                            <Icon name='undo'/>
                                                        </Button>
                                                    </Form.Field>
                                                </Form>
                                            </Segment>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Grid.Column>
                                {showFilterButtons}
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {showArticles}
                </Grid.Row>
                <Grid.Row centered>
                    {this.projectsAmount < this.state.allProjects.length ?
                        <Grid.Column computer={5} tablet={8} mobile={10}>
                            <Button circular fluid className={"call-to-action-button"}
                                    onClick={() => {
                                        this.projectsAmount += ADDER;
                                        this.setState({...this.state});
                                    }}>
                                MEHR AKTUELLES
                            </Button>
                        </Grid.Column>
                        :
                        null
                    }
                </Grid.Row>
                <Grid.Row centered className={"page-row-top"}>
                    <Grid.Column width={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>Unser Netzwerk</h2>
                    </Grid.Column>
                </Grid.Row>

                <Responsive as={Grid.Row} minWidth={992}>
                    <Grid.Column>
                        <Grid>
                            {this.state.networkProjects.length > 0 ?
                                <BlogArticleCarouselComponent articles={this.state.networkProjects}/>
                                :
                                null
                            }
                        </Grid>
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} centered maxWidth={991}>
                    {showNetworkProjects}
                    {this.networkProjectsAmount < this.state.networkProjects.length ?
                        <Grid.Column width={10}>
                            <Button circular fluid className={"call-to-action-button"}
                                    onClick={() => {
                                        this.networkProjectsAmount += ADDER;
                                        this.setState({...this.state});
                                    }}>
                                MEHR AKTUELLES
                            </Button>
                        </Grid.Column>
                        :
                        null
                    }
                </Responsive>
            </Grid>
        );
    }
}

export default CurrentPage