import React from "react";
import {observer} from "mobx-react";
import PropTypes from "prop-types";
import browserHistory from "../../helper/browserHistory";
import {Link} from "react-router-dom";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

@observer
class BlogArticleCardComponent extends React.Component {

    convertToShowDate(dateText) {
        let dateParts = dateText.split("-");
        let jsDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0, 2));

        let month = "";
        switch (jsDate.getMonth()) {
            case 0:
                month = "JANUAR";
                break;
            case 1:
                month = "FEBRUAR";
                break;
            case 2:
                month = "MÄRZ";
                break;
            case 3:
                month = "APRIL";
                break;
            case 4:
                month = "MAI";
                break;
            case 5:
                month = "JUNI";
                break;
            case 6:
                month = "JULI";
                break;
            case 7:
                month = "AUGUST";
                break;
            case 8:
                month = "SEPTEMBER";
                break;
            case 9:
                month = "OKTOBER";
                break;
            case 10:
                month = "NOVEMBER";
                break;
            case 11:
                month = "DEZEMBER";
                break;
            default:
                break;

        }
        return jsDate.getDate() + ". " + month + " " + jsDate.getFullYear();
    }

    render() {
        let {titleText, teaserText, imageLink, buttonLink, dateText, tags = ""} = this.props;
        if (!titleText || !teaserText || !buttonLink || !dateText) {
            console.log('Src is not acceptable');
            return null;
        }

        if (!imageLink || imageLink.replace(/\s/g, '').length === 0) {
            imageLink = "https://www.buergerstiftung-kreis-rv.de/images/Logo.svg";
        }

        return (
            <Card fluid className={"card-shadow bottom-card-padding"}
                  onClick={() => browserHistory.push("/blog-article/" + buttonLink)}
                  style={{"cursor": "pointer"}}>
                <div
                    className={"card-image"}
                    style={{backgroundImage: "linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(0,78,132,0.2) 100%), url('" + imageLink + "')"}}>
                </div>
                <Card.Content>
                    <Card.Meta>
                        <div>
                            <p>{this.convertToShowDate(dateText)}</p>
                            {tags && <div style={{display: "flex", flexFlow: "wrap", rowGap: "5px"}}>
                                {tags.split(",").map(tag => <Label style={{
                                    backgroundColor: '#94bb6a',
                                    color: 'white',
                                    width: "fit-content",
                                    height: "fit-content"
                                }}>{tag}</Label>)}
                            </div>}
                        </div>
                    </Card.Meta>
                    <Card.Header className={"card-header"}>
                        <Link to={"/blog-article/" + buttonLink} className={"black-link"}>
                            <h3>{titleText}</h3>
                        </Link>
                    </Card.Header>
                    <Card.Description>
                        <p className={"text-block"}>
                            {teaserText.length < 150 ?
                                teaserText
                                :
                                teaserText.substring(0, 149) + "..."
                            }
                        </p>
                    </Card.Description>
                    <Button
                        icon={"arrow right"}
                        floated={"right"}
                        className={"card-button card-button-position"}
                        onClick={() => browserHistory.push("/blog-article/" + buttonLink)}
                    />
                </Card.Content>
            </Card>
        );
    }
}

BlogArticleCardComponent.propTypes = {
    titleText: PropTypes.string,
    teaserText: PropTypes.string,
    imageLink: PropTypes.string,
    buttonLink: PropTypes.string,
    dateText: PropTypes.string
};

BlogArticleCardComponent.defaultProps = {
    titleText: null,
    teaserText: null,
    imageLink: null,
    buttonLink: null,
    dateText: null
};

export default BlogArticleCardComponent